$couleur_principale : #2F343B;
$couleur_secondaire: #fff;
$blanc: #fff;

/* Général */
$body_background_color : $couleur_principale;
$content_background_color: #fff;
$texte_lien:$couleur_principale;
$texte_lien_hover:$couleur_principale;
$header_background:$couleur_principale;
$slogan_color_texte:#fff;
$titre_color:#333;
$couleur_text: #333;
$border-generales:#e1e1e1;

$bloc-fil-title-color:#333;
$bloc-fil-title-hover:$couleur_principale;
$bloc-fil-title-background:#ececec;

/* Menu */
$nav_background_color:#fff;
$nav_border_color:$couleur_principale;

$li_background_color:transparent;
$li_background_color_hover:#ececec;
$nav_texte_color:#333;
$nav_texte_color_hover:$couleur_principale;

$sous_nav_background-color:#fff;
$nav_sous_texte_color:$couleur_text;
$nav_sous_texte_color_hover:$texte_lien;

/* Produits */
$etiquette_produit_background:$body_background_color;
$etiquette_produit_texte:#fff;
$fond_produits: #fff;
$produits_text_color: $couleur_text;
$produits_text_color_hover: $texte_lien_hover;

/* Livre d'or */
$message_lo_color:#ececec;

/* Boutons */
$btn_background_color:$couleur_principale;
$btn_texte_color:$blanc;
$btn_background_color_hover:#ececec;
$btn_texte_color_hover:$couleur_principale;

/* Footer */
$footer_background_color:#fff;
$footer_texte_color:#333;

/* Colonne de gauche */
$colonne_gauche_background:#fff;
$colonne_gauche_link_background:$couleur_principale;
$colonne_gauche_link_color:$blanc;
$colone_gauche_border_color:$border-generales;
$colonne_gauche_sous_link_color:$couleur_text;
$colonne_gauche_sous_link_color_hover:$couleur_principale;

/* Template Panier */
$panier-background-color:transparent;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/832series/declinaison";